import Router from 'next/router';
import { useEffect } from 'react';
import { ResponseError } from 'superagent';
import useSWR, { mutate } from 'swr';

import { authToken, AuthType } from '~/utils/authToken';
import { getLoginUrlWithRedirectTo } from '~/utils/getLoginUrlWithRedirectTo';

import { SWR_GLOBAL_CONFIG } from '../_constant';
import { UnauthorizedError } from '../_utils/request';
import { apiGetV1CurrentCorporate, ApiCorporateCurrent } from '../v1/current_corporate/get';
import {
  apiGetV1CurrentOrganization,
  ApiOrganizationCurrent,
} from '../v1/current_organization/get';

type CurrentCorpUser = { userType: 'corp' } & ApiCorporateCurrent;
type CurrentOrgUser = { userType: 'org' } & ApiOrganizationCurrent;
// type CurrentNormalUser = { userType: 'normal' } & unknown;

export type CurrentUser = CurrentCorpUser | CurrentOrgUser; // | CurrentNormalUser;
export type AdminUser = CurrentCorpUser | CurrentOrgUser;
type ApiLoginReturn = {
  corpUser?: CurrentCorpUser;
  orgUser?: CurrentOrgUser;
  // normalUser?: unknown

  currentUser?: CurrentUser;
  adminUser?: AdminUser;
};

export async function apiCurrentLogin(): Promise<ApiLoginReturn> {
  switch (authToken.getType()) {
    case 'no-login':
      return {};

    case 'corporate': {
      const user = await apiGetV1CurrentCorporate();
      const it: CurrentCorpUser = { userType: 'corp', ...user };
      return { corpUser: it, currentUser: it, adminUser: it };
    }
    case 'org': {
      const user = await apiGetV1CurrentOrganization();
      const it: CurrentOrgUser = { userType: 'org', ...user };
      return { orgUser: it, currentUser: it, adminUser: it };
    }
    case 'user': {
      // TODO: #api #current normal user実装する
      return {};
    }
  }
}

const PATH = 'apiCurrentLogin';

type SWROption = {
  /** 指定した場合、userTypeに該当しない時とログインエラーの際はloginに飛ばされる */
  must?: 'corp' | 'org' | 'admin';
};

export function useSWRCurrentLogin(option: SWROption = {}) {
  // NOTE: 管理画面専用の時、authTypeが一致しない場合はログインに遷移する
  useEffect(() => {
    const { must } = option;
    if (!must) return;

    const isValidUserMap = {
      'no-login': false,
      corporate: must === 'corp' || must === 'admin',
      org: must === 'org' || must === 'admin',
      user: false,
    } satisfies Record<AuthType, boolean>;

    const authType = authToken.getType();
    const isValidUser = isValidUserMap[authType];
    if (!isValidUser) {
      Router.replace(getLoginUrlWithRedirectTo());
    }
  }, [option.must]);

  const swr = useSWR(PATH, apiCurrentLogin, { ...SWR_GLOBAL_CONFIG, errorRetryCount: 1 });

  // NOTE: エラーが発生した場合、tokenが無効のためログインに遷移する
  useEffect(() => {
    const e = swr.error;
    if (!e) return;
    const isFailedLogin = e instanceof UnauthorizedError || (e as ResponseError).status === 404;
    const isRedirectLogin = option.must != null;
    if (isFailedLogin && isRedirectLogin) {
      Router.replace(getLoginUrlWithRedirectTo());
    }
  }, [swr.error]);

  return { ...swr, ...(swr.data ?? {}) };
}

export async function clearCacheCurrentLogin() {
  await mutate(PATH, undefined, { revalidate: false });
}
