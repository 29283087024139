import { request } from '~/api/_utils/request';

import { ApiOrganization } from '../organizations/get';
import { ApiSocialIssue } from '../social_issue_categories/get-type';

const PATH = '/v1/current_organization';

export async function apiGetV1CurrentOrganization() {
  const response = await request.GET<{ organization: ApiOrganizationCurrent }>(PATH);
  return response.organization;
}

/** railsの`app/serializers/v1/organization_serializers/current.rb`に該当 */
export interface ApiOrganizationCurrent extends ApiOrganization {
  replies: unknown[];
  social_issues: ApiSocialIssue[];
  email: string;
}
