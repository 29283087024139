import { request } from '~/api/_utils/request';
import type { ApiCorporate } from '~/api/v1/corporates/get';

const PATH = '/v1/current_corporate';

export async function apiGetV1CurrentCorporate() {
  const response = await request.GET<{ corporate: ApiCorporateCurrent }>(PATH);

  const it = {
    ...response.corporate,
    type: 'corporate' as const,
    image() {
      return this.logo;
    },
  };
  return it;
}

/** railsの app/serializers/v1/corporate_serializers/current.rb に該当 */
export interface ApiCorporateCurrent extends ApiCorporate {
  email: string;
}
