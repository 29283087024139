import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import * as API from '~/_legacy/api';
import { LoginT } from '~/_legacy/api/Session';
import { WithLegacy } from '~/_legacy/components/WithLegacy';
import { FormInput, FormButton } from '~/_legacy/components/forms';

import { clearCacheCurrentLogin } from '~/api/_compositions/apiCurrentLogin';
import { OnlyWeb } from '~/components/_for-next/OnlyWeb';
import { WithNextSeo } from '~/components/_for-next/WithNextSeo';
import Layout from '~/pages/_layout.page';
import { CurrentUserState } from '~/state/currentUser';
import { media, alertResponseError, authToken } from '~/utils';

import LoadingCircleIcon from '@public/icons/loading-circle.svg';

export default function Page() {
  return (
    <WithNextSeo title='ログイン'>
      <OnlyWeb>
        <WithLegacy>
          <Login />
        </WithLegacy>
      </OnlyWeb>
    </WithNextSeo>
  );
}

function Login() {
  // TODO: #api #current useSWRCurrentLoginに置き換えたら消す
  const setCurrentUser = useSetRecoilState(CurrentUserState);
  const searchParams = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    authToken.delete();
    clearCacheCurrentLogin();
  }, []);

  const form = useForm<LoginT>({});
  const onSubmit = form.handleSubmit(async (params) => {
    setIsLoading(true);
    const redirectTo = decodeURIComponent(searchParams.get('redirect_to') ?? '/');
    const result = await API.Session.create(params).catch(alertResponseError);
    if (!result) {
      setIsLoading(false);
      return;
    }
    setCurrentUser(result);
    location.href = redirectTo;
  });

  return (
    <Layout pageTitle='ログイン'>
      <StyledFormContainer>
        <StyledForm onSubmit={onSubmit}>
          <StyledLabel>ログイン</StyledLabel>
          <FormInput.ForHookForm
            type='email'
            placeholder='メールアドレス'
            control={form.control}
            name='email'
            autoComplete='username'
            rules={{ required: 'メールアドレスを入力してください' }}
          />
          <FormInput.ForHookForm
            type='password'
            placeholder='パスワード'
            control={form.control}
            name='password'
            autoComplete='current-password'
            rules={{ required: 'パスワードを入力してください' }}
          />
          <FormButton type='submit' disabled={isLoading}>
            <span style={{ position: 'relative' }}>
              {isLoading && <LoadingCircleIcon style={{ position: 'absolute', left: -28 }} />}
              ログイン
            </span>
          </FormButton>
          <StyledForgotPasswordContainer>
            <StyledForgotPassword href='/login/reset'>
              {'> パスワードをお忘れですか？'}
            </StyledForgotPassword>
          </StyledForgotPasswordContainer>

          <StyledLabel>新規アカウントを作成する</StyledLabel>
          <StyledSignUpContainer>
            <Link href='/user/signup'>
              <StyledSignUp>
                個人アカウント
                <br />
                を作成する
              </StyledSignUp>
            </Link>
            <Link href='/org/signup'>
              <StyledSignUp>
                組織アカウント
                <br />
                を作成する
              </StyledSignUp>
            </Link>
          </StyledSignUpContainer>
        </StyledForm>
      </StyledFormContainer>
    </Layout>
  );
}

const StyledHeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
`;

const StyledFormContainer = styled.div`
  padding: 0 20px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #dedede;
`;

const StyledForm = styled.form`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledForgotPasswordContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: end;
`;

const StyledForgotPassword = styled(Link)`
  :hover {
    text-decoration: underline;
  }
`;

const StyledLabel = styled.div`
  width: 100%;
  margin-top: 60px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
`;

const StyledSignUpContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12%;
`;

const StyledSignUp = styled.div`
  margin-bottom: 40px;
  aspect-ratio: 1 / 1;
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 165%;
  text-align: center;
  color: #505050;

  ${media.sp`
    font-size: 18px;
  `}
`;
